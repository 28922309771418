<template>
  <div class="details">
    <div class="content">
      <b-row>
        <b-col
          class="align-self-start"
          :class="{ 'text-center' : breakpoint === 'xs' }"
          cols="12"
          sm="4"
          md="3">
          <div class="profile-photo-container">
            <img
              class="img-fluid profile-photo"
              :src="profilePhoto"
              alt="Healthy Hip Hop Profile Photo"
              v-if="profilePhoto"
            />
            <img
              class="img-fluid profile-photo"
              src="../../assets/images/sample_user_profile.png"
              alt="Healthy Hip Hop Profile Photo"
              v-else
            />
            <b-button
              class="btn btn-link upload-profile-btn v-step-9"
              @click="openUploadPhotoModal">
              <span class="fa fa-camera"></span>
            </b-button>
          </div>
        </b-col>

        <b-col
          class="align-self-center wb"
          :class="{ 'text-center' : breakpoint === 'xs' }"
          cols="12"
          sm="8"
          md="5">
          <h4 class="nickname"> {{ nickname }} </h4>
          <b-input-group class="name-disp" prepend="Name">
            <b-form-input
              type="text"
              v-model="name"
              readonly
            ></b-form-input>
          </b-input-group>

          <b-input-group
            class="email-disp"
            prepend="Email"
            v-if="email">
            <b-form-input
              type="text"
              v-model="email"
              readonly
            ></b-form-input>
          </b-input-group>

          <div class="text-center">
            <b-button
              class="btn-h3-orange"
              @click="$emit('toggle-edit-form')"
              size="sm">
              Edit Profile
            </b-button>

            <b-button
              class="btn-h3-orange"
              type="button"
              size="sm"
              v-if="!profile.user_profiles_id && (user.user_type == 2 ||
                user.user_type == 3 || user.user_type == 4 ||
                user.user_type == 7)"
              @click="checkPin">
              Manage Profiles
            </b-button>

            <b-button
              class="btn-h3-orange"
              type="button"
              size="sm"
              to="/subscription"
              v-if="user.user_type === 6">
              Subscription
            </b-button>
          </div>
        </b-col>

        <b-col
          :class="{
            'offset-sm-4': breakpoint === 'sm',
            'school-col': breakpoint !== 'xs' && breakpoint !== 'sm'
          }"
          cols="12"
          sm="8"
          md="4">
          <h4 class="bio text-center">Bio</h4>
          <b-form-textarea
            class="bio-disp"
            v-model="bio"
            rows="7"
            readonly
          ></b-form-textarea>
        </b-col>
      </b-row>
    </div>

    <!-- Tour -->
    <div class="tour-container">
      <v-tour
        name="gettingStartedProfile"
        :steps="steps"
        :options="tourOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :skip="tour.skip"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              :highlight="tour.highlight">
              <div slot="actions">
                <b-button
                  class="tour-buttons"
                  size="sm"
                  variant="primary"
                  @click="openUploadPhotoModal"
                  block>
                  Next
                </b-button>
              </div>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import FilterMixin from '@/mixins/FilterMixin';
  import PinModal from '@/components/pincode/PinModal';
  import ProfilePhotoModal from './UploadPhotoModal';
  require('vue-tour/dist/vue-tour.css');

  // const EditSchoolModal = () => import('@/components/modals/EditSchool');

  export default {
    name   : 'ProfileDetails',
    mixins : [
      BreakpointMixin,
      FilterMixin,
    ],
    data() {
      return {
        steps : [
          {
            target : '.v-step-9',
            params : {
              enableScrolling : false,
              highlight       : true,
            },
            header  : { title : 'Edit Photo' },
            content : 'Click this icon to edit your profile photo',
          },
        ],
        tourOptions : {
          highlight             : true,
          debug                 : true,
          useKeyboardNavigation : false,
        },
      }
    },
    computed : {
      ...mapGetters({
        user    : 'user/user',
        profile : 'profile/info',
      }),
      nickname() {
        const nickname =
          (this.$store.getters['profile/info'].user_profiles_id) ?
            this.$store.getters['profile/info'].profile_name :
            this.$store.getters['user/user'].nickname;

        return nickname;
      },
      name() {
        const name = (this.$store.getters['profile/info'].user_profiles_id) ?
          this.$store.getters['profile/info'].profile_name :
          this.$store.getters['user/user'].firstname + ' ' +
          this.$store.getters['user/user'].lastname;

        return name;
      },
      email() {
        return (this.$store.getters['profile/info'].user_profiles_id) ? '' :
          this.$store.getters['user/user'].email;
      },
      profilePhoto() {
        return (this.$store.getters['profile/info'].user_profiles_id) ?
          this.$store.getters['profile/info'].profile_img_url :
          this.$store.getters['user/user'].profile_img_url;
      },
      bio() {
        return (this.$store.getters['profile/info'].user_profiles_id) ?
          this.$store.getters['profile/info'].bio :
          this.$store.getters['user/user'].bio;
      },

      // schoolName() {
      //   return this.school.school_name || 'No School';
      // },
      // schoolLogo() {
      //   return this.school.logo_url ||
      //     process.env.VUE_APP_ADMIN_BUCKET_BASE_URL + 'school/default.png';
      // },
      // initialSchool() {
      //   return this.user.school_id || null;
      // },
    },

    // components : {
    //   'SchoolWidget' : () => import('@/components/shared/SchoolWidget'),
    // },
    methods : {
      
      /**
       * Show Getting Started
       */
      showGettingStarted() {
        this.$tours['gettingStartedProfile'].start();
      },

      /**
       * Stop Getting Started
       */
      stopGettingStarted() {
        this.$tours['gettingStartedProfile'].stop();
      },

      /**
       * Upload Photo Modal
       */
      openUploadPhotoModal() {
        this.stopGettingStarted();
        this.$modal.show(ProfilePhotoModal, {
          currentPhotoUrl : '',
          uploadPhotoGS   : (this.user.first_photo_update === 0) ? true : false,
        }, {
          height       : 'auto',
          adaptive     : true,
          clickToClose : (this.user.first_photo_update === 0) ? false : true,
        }, {
          'closed' : async (event) => {
            // updated photo successfully
            if (event.updatedPhoto) {
              if (this.user.first_photo_update === 0) {
                // this.stopGettingStarted();

                // update first_photo_update
                await this.$http.put('api/user', {
                  'updateFields' : {
                    'first_photo_update' : 1,
                  },
                }).then(() => {
                  this.$store.dispatch('user/getUpdatedUser');
                });

                // update level
                await this.$http.post('api/user/level', {
                  id    : this.user.user_id,
                  event : 'END_TOUR',
                }).then(async () => {
                  this.$store.dispatch('user/getUpdatedUser');
                });
              }
            }

            // go back to previous step
            if (event.previousStep)
              this.showGettingStarted();
          },
        });
      },

      /**
       * Check Pin Code
       */
      checkPin() {
        this.$modal.show(PinModal, {
          action : 'check',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },

      /**
       * Open Edit School Modal
       */
      // openEditSchool() {
      //   this.$modal.show(EditSchoolModal, {
      //     school : this.school,
      //   }, {
      //     height   : 'auto',
      //     width    : this.windowWidth >= 992 ? '80%' : '100%',
      //     maxWidth : this.windowWidth >= 992 ? 800 : 600,
      //     adaptive : true,
      //   }, {
      //     'before-close' : (event) => {
      //       this.getSchool();
      //     },
      //   });
      // },

      /**
       * Get School
       */
      // getSchool() {
      //   this.$http.get('api/school/with_details', {
      //     params : {
      //       id : this.user.school_id,
      //     },
      //   }).then(response => {
      //     this.school = response.data;
      //   }).catch(() => {
      //     this.school = {};
      //   });
      // },

      /**
       * Update User School
       * @param newSchoolId
       */
      // changeSchool(newSchoolId) {
      //   if (newSchoolId !== this.user.school_id) {
      //     this.$http.put('api/user', {
      //       'userId'       : this.user.user_id,
      //       'updateFields' : {
      //         'school_id' : newSchoolId,
      //       },
      //     }).then(() => {
      //       this.$store.dispatch('user/getUpdatedUser');
      //       this.$notify({
      //         group : 'notif',
      //         type  : 'success',
      //         title : 'Success!',
      //         text  : 'School has been updated!',
      //       });
      //     }).catch(() => {
      //       this.$notify({
      //         group : 'notif',
      //         type  : 'error',
      //         title : 'Error!',
      //         text  : 'OOPS! Something went wrong.',
      //       });
      //     }).finally(() => {
      //       this.getSchool();
      //       this.selectNewSchool = false;
      //     });
      //   }
      // },
    },

    // created() {
    //   const user = this.$store.getters['user/user'];
    //   if ((user.user_type === 3 || user.user_type === 4) && user.school_id)
    //     this.getSchool();
    // },
    mounted() {
      const user = this.$store.getters['user/user'];
      if (user.first_photo_update === 0 && user.user_type !== 6)
        this.showGettingStarted();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .details {
    background-color: rgba(52, 45, 255, 0.6);
    box-shadow: 3px 10px 10px rgba(0,0,0,0.5);

    .content {
      padding: 1.5rem;

      .nickname, .bio {
        -webkit-text-stroke: 2px #a1409d !important;
      }
      
      .profile-photo {
        width: 100%;
        background-color: #fff;
        border: 5px solid #a1409d;
      }

      .name-disp, .email-disp {
        // padding-left: 1rem;
        background-color: #e7932d;
        border: 3px solid #9831ff;

        .input-group-text {
          width: 8rem;
          background-color: #e7932d;
          color: #fff !important;
          font-family: 'WickedMouse' !important;
          -webkit-text-stroke: 1px #a1409d !important;
        }
      }
      

      .bio-disp {
        border: 3px solid #9831ff;
      }

      p {
        color: $primary-color;
        font-size: 1.5em !important;
      }
      p.big {
        font-size: 2em !important;
      }
    }
  }

  .col-form-label {
    color: #fff !important;
    font-family: 'WickedMouse' !important;
  }
  
  // .btn {
  //   font-size: 1em !important;
  // }
  .upload-profile-btn {
    position: absolute;
    right: 0.5em;
    border: none;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.50);
    // color: $primary-color;
    font-size: 2em !important;

    &:hover, &:active {
      background-color: rgba(255, 255, 255, 0.50) !important;
      border-color:  rgba(255, 255, 255, 0.50) !important;
      color: $primary-color !important;
    }
  }

  // .school-col {
  //   border-left: 1px solid $primary-color;
  // }

  .tour-buttons {
    font-size: 1em !important;
  }

  @media only screen and (max-width: $xs-max) {
    // .header {
    //   p {
    //     font-size: 1.5em !important;
    //   }
    // }
    
    .profile-photo {
      width: 90% !important;
    }
    .upload-profile-btn {
      right: 1em;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .profile-photo {
      width: 100% !important;
    }
    .upload-profile-btn {
      right: 0.5em;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    
  }

</style>