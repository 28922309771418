<template>
  <div>
    <AppModal
      dialogTitle="Update Photo"
      :disableClose="true">
      <b-form @submit.prevent="prepareUpdate">
        <b-row>
          <b-col class="d-flex align-items-center mx-auto">
            <!-- Selected Avatar Preview -->
            <div
              class="photo-placeholder v-step-11"
              v-if="selectedAvatar != null || currProfileImg" >
              <i
                class="fas fa-times-circle remove-icon"
                 @click="deselectAvatar">
              </i>
              <img
                class="current-img img-fluid"
                :src="selectedAvatar"
                alt="Healthy Hip Hop Avatar"
                width="166"
                height="166"
                v-if="selectedAvatar"
              />
              <img
                class="current-img img-fluid"
                :src="currProfileImg"
                alt="Healthy Hip Hop Avatar"
                v-else
              />
            </div>

            <!-- Upload Profile Photo -->
            <div class="photo-placeholder v-step-11" v-else>
              <croppa
                v-model="file"
                accept=".jpeg,.png,.jpg"
                :width="166"
                :height="166"
                :placeholder="placeholder"
                placeholder-color="#068bcd"
                :placeholder-font-size="12"
                :prevent-white-space="true"
                canvas-color="#fff"
                :show-remove-button="true"
                remove-button-color="red"
                :remove-button-size="25"
                :show-loading="true"
                :loading-size="25"
                @file-type-mismatch="onFileTypeMismatch"
                @new-image="imageUpdate('new')"
                @image-remove="imageUpdate('removed')">
              </croppa>
              <div class="buttons">
                <i
                  class="fas fa-search-plus icon"
                  title="Zoom In"
                  @click="file.zoomIn()">
                </i>
                <i
                  class="fas fa-search-minus icon"
                  title="Zoom Out"
                  @click="file.zoomOut()">
                </i>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="v-step-10">
            <p class="text-center mt-3 pl-3 pr-3">Pick an avatar:</p>
            <div class="avatars">
              <span
                class="position-relative ml-1 mr-1"
                v-for="(avatar, index) in defaultAvatars"
                :key="index"
                @click="selectAvatar(index)"
              >
                <img
                  class="avatar"
                  :class="{
                    'border-dark' : (index == selected) ? true : false
                  }"
                  :src="avatar.asset_url"
                  :alt="avatar.filename"
                />
              </span>
            </div>
          </b-col>
        </b-row>
        <div class="w-50 mx-auto pt-3">
          <b-button
            class="btn mx-2 btn-h3-small v-step-12"
            type="submit"
            variant="success"
            :disabled="disableButton || loading"
            >
            Update
          </b-button>
          &nbsp;
          <b-button
            class="btn btn-danger btn-h3-small"
            type="button"
            @click="$emit('close')"
            :disabled="uploadPhotoGS === true">
            Cancel
          </b-button>
        </div>
      </b-form>
    </AppModal>

    <!-- Tour -->
    <div class="tour-container">
      <v-tour
        name="gettingStartedProfile2"
        :steps="steps"
        :options="tourOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :skip="tour.skip"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              :highlight="tour.highlight">
              <div slot="actions">
                <b-button
                  class="tour-buttons"
                  size="sm"
                  variant="primary"
                  @click="tour.previousStep"
                  :disabled="tour.currentStep < 1"
                  block
                  v-if="tour.currentStep === (steps.length - 1)">
                  Previous
                </b-button>
                <b-row v-else>
                  <b-col cols="6">
                    <b-button
                      class="tour-buttons"
                      size="sm"
                      variant="primary"
                      @click="tour.previousStep"
                      :disabled="tour.currentStep < 1"
                      block>
                      Previous
                    </b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      class="tour-buttons"
                      size="sm"
                      variant="primary"
                      @click="tour.nextStep"
                      block>
                      Next
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </div>

    <AppLoader :isLoading="loading" />
  </div>
</template>


<script>
  import 'vue-croppa/dist/vue-croppa.css';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapGetters } from 'vuex';
  import { setTimeout } from 'timers';
  import { required } from 'vuelidate/lib/validators';
  require('vue-tour/dist/vue-tour.css');

  const mustBeImg = function (value) {
    const allowedTypes = [ 'image/jpeg', 'image/png', 'image/jpg' ];
    let isValid = false;

    if (value !== null)
      isValid = (!allowedTypes.includes(value.type)) ? false : true;
    else
      isValid = true;

    return isValid;
  };

  export default {
    props : {
      uploadPhotoGS : {
        type    : Boolean,
        default : false,
      },
    },
    data() {
      return {
        loading        : false,
        uploading      : false,
        disableButton  : true,
        selected       : null,
        selectedAvatar : null,
        file           : {},
        placeholder    : 'Choose an image',
        currProfileImg :
          (this.$store.getters['profile/info'].user_profiles_id) ?
            this.$store.getters['profile/info'].profile_img_url :
            this.$store.getters['user/user'].profile_img_url,
        progress : 0,
        avatars  : [ {
          name : 'Jacob',
          img  : process.env.VUE_APP_CONTENT_BASE_URL +
            'public/profile/defaults/Jacob.png',
        }, {
          name : 'Leo',
          img  : process.env.VUE_APP_CONTENT_BASE_URL +
            'public/profile/defaults/Leo.png',
        }, {
          name : 'Mon',
          img  : process.env.VUE_APP_CONTENT_BASE_URL +
            'public/profile/defaults/Mon.png',
        }, {
          name : 'Owla',
          img  : process.env.VUE_APP_CONTENT_BASE_URL +
            'public/profile/defaults/Owla.png',
        }, {
          name : 'Ralph',
          img  : process.env.VUE_APP_CONTENT_BASE_URL +
            'public/profile/defaults/Ralph.png',
        }, {
          name : 'Tyra',
          img  : process.env.VUE_APP_CONTENT_BASE_URL +
            'public/profile/defaults/Tyra.png',
        }, {
          name : 'Zeb',
          img  : process.env.VUE_APP_CONTENT_BASE_URL +
            'public/profile/defaults/Zeb.png',
        } ],
        steps : [
          {
            target : '.v-step-10',
            params : {
              placement       : 'top',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Select Avatar' },
            content : 'Select from default avatars below',
          }, {
            target : '.v-step-11',
            params : {
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Upload Photo' },
            content : 'Upload a profile photo',
          }, {
            target : '.v-step-12',
            params : {
              placement       : 'top',
              highlight       : true,
              enableScrolling : false,
            },
            header  : { title : 'Update' },
            content : 'Click Update button',
          },
        ],
        tourOptions : {
          highlight             : true,
          debug                 : true,
          useKeyboardNavigation : false,
        },
      }
    },
    computed : {
      ...mapGetters({
        'defaultAvatars' : 'staticAsset/defaultAvatars',
        'profile'        : 'profile/info',
      }),
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
      'AppModal'  : () => import('@/components/layout/AppModal'),
    },
    watch : {
      loading(value) {
        this.disableButton = value;
      },
      selectedAvatar(value) {
        if (value) {
          this.disableButton = (value !==
            this.$store.getters['user/user'].profile_img_url) ? false : true;
        } else
          this.disableButton = true;
      },
    },
    methods : {

      /**
       * Identify which function to call
       */
      prepareUpdate() {
        if (this.profile.user_profiles_id) {
          if (this.selectedAvatar)
            this.updateProfilePhoto();
          else
            this.uploadProfilePhoto();
        } else {
          if (this.selectedAvatar)
            this.updateUserPhoto();
          else
            this.uploadUserPhoto();
        }
      },

      /**
       * Update User Photo
       */
      updateUserPhoto() {
        this.loading = true;
        this.$http.put('api/user', {
          userId       : this.$store.getters['user/user'].user_id,
          updateFields : {
            'profile_img_url' : this.selectedAvatar,
          },
        }).then(() => {
          this.$tours['gettingStartedProfile2'].stop();
          this.$parent.$emit('closed', { updatedPhoto : true });
          this.$store.dispatch('user/getUpdatedUser');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Updated Profile Photo Successfully!',
          });
          const that = this;
          setTimeout(async function () {
            that.$emit('close');
            that.$emit('success');
          }, 2000);
        }).catch(() => {

        }).finally(() => {
          this.loading = false;
        });
      },

      /**
       * Upload Image File
       */
      uploadUserPhoto() {
        this.loading = true;
        this.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('file', blob);
          formData.append('userId', this.$store.getters['user/user'].user_id);

          try {
            const result = await this.$http.post(
              'api/upload/user/profile',
              formData,
              {
                onUploadProgress : e =>
                  this.progress = Math.round(e.loaded * 100 / e.total),
              },
            );
            if (this.progress == 100)
              this.loading = false;
            this.currProfileImg = result.data.file;
            this.$store.dispatch('user/getUpdatedUser');
            this.$tours['gettingStartedProfile2'].stop();
            this.$parent.$emit('closed', { updatedPhoto : true });

            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Updated Profile Photo Successfully!',
            });
            const that = this;
            setTimeout(async function () {
              that.$emit('close');
              that.$emit('success');
            }, 2000);
          } catch(err) {
            this.loading = false;
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong!',
            });
          }
        })
      },

      /**
       * Update User Profile Photo
       */
      async updateProfilePhoto() {
        this.loading = true;
        const formData = new FormData();
        formData.append('userProfileId', this.profile.user_profiles_id);
        
        if (this.selectedAvatar)
          formData.append('profileImgUrl', this.selectedAvatar);

        try {
          const result = await this.$http.put('api/user/profile', formData, {
            onUploadProgress : event =>
              this.progress = Math.round(event.loaded * 100 / event.total),
          });
          if (result) {
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Updated Profile Photo Successfully!',
            });
            this.loading = false;
            this.currProfileImg = result.data.file;
            this.$store.dispatch(
              'profile/getInfo',
              this.profile.user_profiles_id);
            this.$emit('close');
            this.$emit('success');
          }
        } catch(err) {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }
      },

      async uploadProfilePhoto() {
        this.loading = true;
        this.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('userProfileId', this.profile.user_profiles_id);
          formData.append('file', blob);

          try {
            const result = await this.$http.put('api/user/profile', formData, {
              onUploadProgress : e =>
                this.progress = Math.round(e.loaded * 100 / e.total),
            });
            if (result) {
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Updated Profile Photo Successfully!',
              });
              this.loading = false;
              this.currProfileImg = result.data.file;
              this.$store.dispatch(
                'profile/getInfo',
                this.profile.user_profiles_id);
              const that = this;
              setTimeout(async function () {
                that.$emit('close');
                that.$emit('success');
              }, 2000);
            }
          } catch(err) {
            this.loading = false;
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }
        })
      },

      /**
       * Assign Selected Avatar
       * @param index
       */
      selectAvatar(index) {
        this.selectedAvatar = this.defaultAvatars[index].asset_url;
        this.selected = index;
      },

      /**
       * Reset Selected Avatar
       */
      deselectAvatar() {
        this.selectedAvatar = null;
        this.selected = null;
        this.currProfileImg = null;
      },

      /**
       * Disable button on image croppa update
       * @param status
       */
      imageUpdate(status) {
        this.selectedAvatar = null;
        this.disableButton = (status === 'new') ? false : true;
        this.updatedPhoto = (status === 'removed') ? false : true;
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch () {
        this.placeholder = 'Please select a jpeg or png file.'
      },
    },
    mounted() {
      if (this.uploadPhotoGS === true)
        setTimeout(() => this.$tours['gettingStartedProfile2'].start(), 1000);
    },
    validations : {
      file : { required, mustBeImg },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .photo-placeholder {
    margin: 0 auto;

    .current-img {
      margin: 0 auto 1em auto;
      width: 166px;
      height: 166px;
      background-color: #fff;
      border: 2px dashed $primary-color;
    }

    .buttons {
      width: 10rem;
      margin: 0 auto;
      text-align: center;

      .icon {
        padding: 0.5rem;
        margin: 0 0.2rem;
        background-color: $primary-color;
        border-radius: 1.5rem;
        color: #fff;
        font-size: 1rem;
        &:hover {
          box-shadow: 0 0 15px rgba(0,0,0,0.2);
          cursor: pointer;
        }
      }
    }

    .remove-icon {
      position: absolute !important;
      top: -2.5px;
      right: 10em;
      background-color: #fff;
      border: 1.5px solid #fff;
      border-radius: 1rem;
      color: red;
      cursor: pointer;
      filter: drop-shadow(-2px 2px 2px rgba(0,0,0,0.7));
      font-size: 1.3rem;
    }
  }

  .v-tour__target--highlighted {
    .remove-icon {
      right: 0;
    }
  }

  .avatars {
    margin: 0 auto;
    text-align: center;
  }

  .avatar {
    height: 4.5em;
    width: 4.5em;
    margin-bottom: .5rem;
    background-color: #fff;
    border: 1px solid #dee2e6 !important;
    border-radius: .5rem;
    cursor: pointer;
  }

  .border-dark {
    border: 1px solid gray !important;
  }

  /* Croppa */
  .croppa-container {
    border: 2px dashed $primary-color;

    &:hover {
      opacity: 1;
      background-color: #8ac9ef;
    }
  }

  .tour-buttons {
    font-size: 1em !important;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    // .remove-icon {
    //   right: calc(100vw / 4);
    // }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {

  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {

  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {

  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
  }

</style>